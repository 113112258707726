import React from 'react';
import ServiceOne from '../../components/Services/ServiceOne';
import ServiceTwo from '../../components/Services/ServiceTwo';
import TabTwo from '../../components/Tabs/TabTwo';
import HeroTwo from './HeroTwo';
import IntroOne from '../../components/IntroVideo/IntroOne';
import TabThree from '../../components/Tabs/TabThree';
import TestimonialOne from '../../components/Testimonial/TestimonialOne';
import NewsLetterTwo from '../../components/NewsLetter/NewsLetterTwo';
import RecentBlog from '../../components/Blogs/RecentBlog';
import LargeCarousel from '../../components/LogoCarousel/LargeCarousel';
import Layout from '../../layout';
import PageMeta from '../../components/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import NavRightTwo from '../../components/NavRight/NavRightTwo';
import Navbar from '../../layout/Header/Navbar';

const HomeSass = () => {
  return (
    <Layout>
      <PageMeta title='Sass — Software &amp; App Startup HTML Template' />
      <Navbar navRight={<NavRightTwo />} />
      <div id='main_content'>
        <HeroTwo />
        <TabTwo />
        <ServiceOne />
        <ServiceTwo />
        <IntroOne />
        <TabThree />
        <LargeCarousel />
        <TestimonialOne />
        <RecentBlog />
        <NewsLetterTwo />
        <FooterOne />
      </div>
    </Layout>
  );
};

export default HomeSass;
