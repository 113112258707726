import React, { Component } from 'react';
import SectionTitle from '../SectionTitle/SectionTitle';

class ServiceTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <div className='service'>
          <div className='container'>
            <SectionTitle
              firstTitle=' Ultraland'
              lastTitle='feature overview'
              description="So I said knees up cuppa such a fibber jeffrey a bit of how's
                  your."
            />
            <div className='row'>
              <div className='col-lg-4 col-md-6 col-sm-6'>
                <div
                  className='tt-icon-box style-two wow fadeInUp'
                  data-wow-delay='0.7s'
                >
                  <div className='icon-container color__one'>
                    <img
                      src='media/feature/trending-up.svg'
                      alt='Direct Access'
                    />
                  </div>

                  <div className='box-content'>
                    <h4 className='box-title'>
                      <a href='service.html'>Direct Access</a>
                    </h4>

                    <p className='description'>
                      Cras loo Richard smashing old pukka victoria sponge say.
                    </p>

                    <a href='service.html' className='tt-more-link'>
                      Read More<i className='feather-arrow-right'></i>
                    </a>
                  </div>
                </div>
              </div>

              <div className='col-lg-4 col-md-6 col-sm-6'>
                <div
                  className='tt-icon-box style-two wow fadeInUp'
                  data-wow-delay='0.9s'
                >
                  <div className='icon-container color__two'>
                    <img src='media/feature/bell.svg' alt='Push Notification' />
                  </div>
                  <div className='box-content'>
                    <h4 className='box-title'>
                      <a href='service'>Push Notification</a>
                    </h4>

                    <p className='description'>
                      Cras loo Richard smashing old pukka victoria sponge say.
                    </p>
                    <a href='service' className='tt-more-link'>
                      Read More<i className='feather-arrow-right'></i>
                    </a>
                  </div>
                </div>
              </div>

              <div className='col-lg-4 col-md-6 col-sm-6'>
                <div
                  className='tt-icon-box style-two wow fadeInUp'
                  data-wow-delay='1.1s'
                >
                  <div className='icon-container color__three'>
                    <img
                      src='media/feature/tablet.svg'
                      alt='Mobile Capability'
                    />
                  </div>

                  <div className='box-content'>
                    <h4 className='box-title'>
                      <a href='service.html'>Mobile Capability</a>
                    </h4>

                    <p className='description'>
                      Cras loo Richard smashing old pukka victoria sponge say.
                    </p>

                    <a href='service.html' className='tt-more-link'>
                      Read More<i className='feather-arrow-right'></i>
                    </a>
                  </div>
                </div>
              </div>

              <div className='col-lg-4 col-md-6 col-sm-6'>
                <div
                  className='tt-icon-box style-two wow fadeInUp'
                  data-wow-delay='1.3s'
                >
                  <div className='icon-container color__four'>
                    <img src='media/feature/umbrella.svg' alt='Direct Access' />
                  </div>

                  <div className='box-content'>
                    <h4 className='box-title'>
                      <a href='service.html'>Direct Access</a>
                    </h4>

                    <p className='description'>
                      Cras loo Richard smashing old pukka victoria sponge say.
                    </p>

                    <a href='service' className='tt-more-link'>
                      Read More<i className='feather-arrow-right'></i>
                    </a>
                  </div>
                </div>
              </div>

              <div className='col-lg-4 col-md-6 col-sm-6'>
                <div
                  className='tt-icon-box style-two wow fadeInUp'
                  data-wow-delay='1.5s'
                >
                  <div className='icon-container color__five'>
                    <img
                      src='media/feature/cloud.svg'
                      alt='Push Notification'
                    />
                  </div>

                  <div className='box-content'>
                    <h4 className='box-title'>
                      <a href='service.html'>Push Notification</a>
                    </h4>

                    <p className='description'>
                      Cras loo Richard smashing old pukka victoria sponge say.
                    </p>

                    <a href='service.html' className='tt-more-link'>
                      Read More<i className='feather-arrow-right'></i>
                    </a>
                  </div>
                </div>
              </div>

              <div className='col-lg-4 col-md-6 col-sm-6'>
                <div
                  className='tt-icon-box style-two wow fadeInUp'
                  data-wow-delay='1.7s'
                >
                  <div className='icon-container color__six'>
                    <img
                      src='media/feature/user-check.svg'
                      alt='Mobile Capability'
                    />
                  </div>

                  <div className='box-content'>
                    <h4 className='box-title'>
                      <a href='service.html'>Mobile Capability</a>
                    </h4>

                    <p className='description'>
                      Cras loo Richard smashing old pukka victoria sponge say.
                    </p>

                    <a href='service.html' className='tt-more-link'>
                      Read More<i className='feather-arrow-right'></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceTwo;
