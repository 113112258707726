import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/effect-fade/effect-fade.min.css';
import 'react-modal-video/scss/modal-video.scss';

ReactDOM.render(<App />, document.getElementById('root'));
