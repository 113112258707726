import React from 'react';
import { Link } from 'react-router-dom';

const NavRightTwo = ({ warningBtn }) => {
  return (
    <>
      <div className={`nav-right ${warningBtn ? 'style3' : 'style2'}`}>
        <Link to='/login' className='nav-link'>
          <i className='feather-unlock'></i>Login
        </Link>
        <Link to='/contact' className='nav-btn tt__btn'>
          Get Started
        </Link>
      </div>
    </>
  );
};

export default NavRightTwo;
